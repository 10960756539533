<template>
    <Card @click="onTimelineCard()" :column="column">
        <template #headline>
            <span>{{ subcontract }}</span>
        </template>
        <time v-if="from">{{ formatDateToFnsDate(from.toDate()) }}</time>
        <p>{{ description }}</p>
    </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import methods from '@/utils/methods';
import useState from '@/store';

export default {
    name: 'TimelineCard',
    components: {
        Card,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        subcontract: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        time: {
            type: String,
            default: null,
        },
        created: {
            type: Object,
            default: null,
        },
        column: {
            type: Number,
            default: 1,
        },
        day: {
            type: Number,
            default: 1,
        },
        from: {
            type: Object,
            default: null,
        },
        to: {
            type: Object,
            default: null,
        },
    },
    setup() {
        const { setModalData } = useState();
        return { setModalData };
    },
    computed: {
        statusColor() {
            return this.isPaid() ? 'green' : 'yellow';
        },
        statusText() {
            const percent = Math.round((this.payment.paid / this.payment.price) * 100);
            return `Betalt ${percent}%`;
        },
    },
    mixins: [methods],
    methods: {
        onTimelineCard() {
            const data = this.removeNullValues(this.$props);
            this.setModalData(data);
            this.$router.push({ name: 'edit-timeline', params: { id: data.id } });
        },
        isPaid() {
            return this.payment.price <= this.payment.paid;
        },
    },
};
</script>
